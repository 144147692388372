<template>
  <a-spin :spinning="spinning" :tip="tipText" size="large">
    <section class="assessment-scroll">
      <section class="assessment-wrap">
        <section class="content-wrap">
          <section class="left-wrap">
            <section class="scroll-wrap">
              <a-collapse v-model:activeKey="collapseActiveKey" :bordered="false">
                <template #expandIcon="{ isActive }">
                  <caret-right-outlined :style="collapseArrow" :rotate="isActive ? 90 : 0" />
                </template>
                <a-collapse-panel v-for="(realm, realmIndex) in realmsList" :key="realmIndex">
                  <template #header>
                    <section class="collapse-header" :class="!realm.disabled ? '' : 'collapse-disabled'">
                      {{ toChinesNum(realmIndex + 1) }}、{{ realm.name }}({{ realm.score }}分)
                    </section>
                  </template>
                  <div :class="!realm.disabled ? '' : 'collapse-disabled'">
                    <div
                      class="metric-wrap"
                      :class="activeMetricIdToKeyBySelf == metric.metricIdToKeyBySelf ? 'metric-wrap-active' : ''"
                      v-for="(metric, metricIndex) in realm.metrics"
                      :key="metricIndex"
                      @click="changeMetric(metric)"
                    >
                      <span class="metric-name"> {{ metric.showIndex }}.{{ metric.name }}</span>
                      ({{ metric.score }}分)
                    </div>
                  </div>

                  <template #extra>
                    <div class="realm-extra" @click="(e) => handleRealmReject(e, realm)">
                      <template v-if="Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) == 4">
                        <div class="complete"><img :src="require('@src/assets/complete.png')" />已通过</div>
                      </template>
                      <template v-else-if="Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) == 3">
                        <div class="complete-not-other">
                          <img :src="require('@src/assets/complete_not_other.png')" />驳回
                        </div>
                      </template>
                      <template v-else-if="Number(realmResults[Number(realm.realmNo)].countyEvaluateHandleStatus) == 2">
                        <div class="complete-not-other">
                          <img :src="require('@src/assets/complete_not_other.png')" />驳回复评
                        </div>
                      </template>
                      <template v-else>
                        <div class="complete-not"><img :src="require('@src/assets/complete_not.png')" />未审核</div>
                      </template>
                    </div>
                  </template>
                </a-collapse-panel>
              </a-collapse>
            </section>
          </section>
          <section class="middle-blank"></section>
          <section class="right-wrap">
            <div class="scroll-wrap" ref="right">
              <div class="project-name">
                {{ project.name }}（{{ project.regionType == 'community' ? '城镇社区' : '农村社区' }}）
              </div>
              <div>
                <div class="metric-view-name">{{ metricView.name }}({{ metricView.score }}分)</div>
                <div class="metric-view-title">一、指标说明</div>
                <div class="metric-view-text">{{ metricView.note }}</div>
                <div class="metric-view-title">二、赋分标准</div>
                <div
                  v-for="(markingNoteText, markingNoteTextIndex) in metricView.markingNoteTexts"
                  :key="markingNoteTextIndex"
                  class="metric-view-text"
                >
                  {{ markingNoteTextIndex + 1 }}.{{ markingNoteText }}
                </div>
                <div class="metric-view-title">
                  <span class="text">三、评价方式</span>
                  <a-radio-group
                    button-style="solid"
                    v-model:value="metricView.evaluateActiveKey"
                    @change="
                      (e) => {
                        metricView.evaluateActiveKey = e.target.value;
                      }
                    "
                  >
                    <a-radio-button
                      :value="valIndex"
                      v-for="(val, valKey, valIndex) in metricView.metricEvaluates"
                      :key="valIndex"
                    >
                      {{ transformKeyToTextByDictionaries(valKey) }}
                    </a-radio-button>
                  </a-radio-group>
                </div>
                <template
                  v-for="(metricEvaluateObj, metricEvaluateIndex) in metricEvaluatesByKey"
                  :key="metricEvaluateIndex"
                >
                  <div class="evaluate-note">
                    <span v-if="metricEvaluateObj.required == 'yes'"> * </span>
                    {{ metricEvaluateIndex + 1 + '.' + metricEvaluateObj.note }}
                  </div>
                  <div class="evaluate-list">
                    <div class="evaluate-list-title" v-if="metricView.showType == 'ordinary'">
                      <div class="title">佐证材料</div>
                    </div>

                    <div class="evaluate-list-content">
                      <div
                        v-for="(tt, ttIndex) in selfEvaluateEvidencesEmptyAdd(metricEvaluateObj.selfEvaluateEvidences)"
                        :key="ttIndex"
                        class="evaluate-item"
                      >
                        <a-textarea
                          class="textarea-look"
                          v-model:value="tt.evidence"
                          :autoSize="{
                            minRows: 6,
                            maxRows: 6
                          }"
                          :disabled="true"
                        />

                        <div class="file-gourp-wrap">
                          <div class="file-wrap">
                            <div class="file-item" v-for="(file, fileIndex) in tt.fileList" :key="fileIndex">
                              <div class="file-item-flex">
                                <span class="file-name"> {{ fileIndex + 1 }}.{{ file.name }} </span>
                                <span v-if="!isImage(file)" class="file-preview" @click="goPreview(file)">
                                  在线预览
                                </span>
                                <span class="file-down" @click="handleDownload(file)">立即下载</span>
                              </div>
                              <div class="file-image" v-if="isImage(file)">
                                <a-image :width="120" :src="file.url" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </section>
        </section>
        <section class="footer-wrap">
          <div class="footer-flex">
            <div class="footer-left">
              <div>{{ metricView.showIndex }}.{{ metricView.name }}</div>
              <div class="right-button-line">
                <div class="footer-button">
                  <a-button @click="preMetric" size="large" block>上一项</a-button>
                </div>
                <div class="footer-button">
                  <a-button @click="nextMetric" size="large" block>下一项</a-button>
                </div>
              </div>
            </div>
            <div class="flex-line"><span></span></div>
            <div class="footer-middle">
              <div class="current-score-line">
                <span class="label">自评得分：</span>
                <span class="number-look">{{ metricView.selfEvaluateScore }}</span>
                <span class="mark">分</span>
              </div>
              <div class="current-score-line">
                <span class="label">自评总得分：</span>
                <span class="number-look">{{ Number(Number(selfEvaluateScore).toFixed(2)) }}</span>
                <span class="mark">分</span>
              </div>
            </div>
            <div class="flex-line"><span></span></div>
            <div class="footer-right">
              <div class="realmView-name">
                {{ toChinesNum(realmView.realmIndex + 1) }}、{{ realmView.name }}({{ realmView.score }}分)
              </div>

              <div class="right-button-line" v-if="hideButton != 1">
                <div class="footer-button">
                  <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="handleAudit('pass')">
                    <template #title> 确定通过吗 </template>
                    <a-button size="large" type="primary" block>通过</a-button>
                  </a-popconfirm>
                </div>
                <div class="footer-button">
                  <a-button size="large" danger block @click="handleAudit('reject')">驳回</a-button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <previewFile :file="activeFile" v-if="visibleModalFile" @onCancel="onCancelModalFile"></previewFile>
      <mp3or4
        :fileUrl="activeFileUrl"
        :fileType="activeFileType"
        v-if="visibleModalMp"
        @onCancel="visibleModalMp = false"
      ></mp3or4>

      <auditModal
        :activeId="activeId"
        :realmNo="activeRealmNo"
        :activeRealmNoRemark="activeRealmNoRemark"
        v-if="visibleModalAudit"
        @onCancel="oncancelAudit"
        @onOk="onOkModalAudit"
      />

      <remarkModal
        :operationLogs="operationLogs"
        v-if="visibleModalRemark"
        @onCancel="visibleModalRemark = false"
      ></remarkModal>
    </section>
  </a-spin>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { CaretRightOutlined, CheckCircleFilled, DeleteOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { apiGetProjectDetailByLook, apiPostCountyAuditingByRealm } from '@src/apis/community';
import { previewPdfApi } from '@src/apis/index';
import { downloadFile2 } from '@src/utils';
import { previewFile, mp3or4 } from '@src/components';

import auditModal from './components/auditModal';
import remarkModal from './components/remarkModal';
export default {
  data() {
    return {
      activeFileUrl: '',
      activeFileType: '',
      visibleModalMp: false,

      visibleModalRemark: false,
      operationLogs: '', //此处本来为string,改为list

      visibleModalAudit: false,
      activeId: '',
      activeRealmNo: '',
      //
      collapseArrow: {
        color: '#C5C5C5'
      },
      //
      collapseActiveKey: [],
      project: {},
      projectResult: {},
      projectResultId: '',
      activeMetricIdToKeyBySelf: '',
      minMetricIdToKeyBySelf: '',
      maxMetricIdToKeyBySelf: '',
      previewFlag: false, //预览
      selfEvaluateScore: 0,
      requestFlag: false,
      activeFile: {},
      visibleModalFile: false,
      realmsList: [], //领域数组
      realmResults: {}, //领域结果对象
      tipText: '加载中......',
      spinning: false,
      activeRealmNoRemark: '',
      realmOperationLogs: '', //审核记录Map
      hideButton: 0
    };
  },
  components: {
    CaretRightOutlined,
    CheckCircleFilled,
    DeleteOutlined,
    LoadingOutlined,
    previewFile,
    mp3or4,
    auditModal,
    remarkModal
  },
  created() {
    const _this = this;
    _this.projectResultId = _this.$route.query.id;
    _this.hideButton = _this.$route.query.hideButton;
    _this.$nextTick(async () => {
      await _this.getDetails();
    });
  },
  computed: {
    ...mapGetters(['userinfo', 'dictionaries', 'token']),
    evaluateType() {
      return this.dictionaries.evaluateType || [];
    },
    realmView() {
      let realmObject = {};
      if (this.realmsList && this.realmsList.length > 0) {
        this.realmsList.forEach((realm, realmIndex) => {
          realm.metrics.forEach((metric, metricIndex) => {
            if (metric.metricIdToKeyBySelf == this.activeMetricIdToKeyBySelf) {
              realmObject = Object.assign(realm, { realmIndex });
            }
          });
        });
      }
      return realmObject;
    },
    metricView() {
      let metricObject = {};
      if (this.realmsList && this.realmsList.length > 0) {
        this.realmsList.forEach((realm, realmIndex) => {
          realm.metrics.forEach((metric, metricIndex) => {
            if (metric.metricIdToKeyBySelf == this.activeMetricIdToKeyBySelf) {
              metricObject = metric;
            }
          });
        });
      }

      return metricObject;
    },
    metricEvaluatesByKey() {
      let metricEvaluatesByFilter = [];
      if (this.metricView && this.metricView.metricEvaluates) {
        let evaluateActiveKey = this.metricView.evaluateActiveKey || 0;
        let keys = Object.keys(this.metricView.metricEvaluates) || [];
        let key = keys[evaluateActiveKey];
        metricEvaluatesByFilter = this.metricView.metricEvaluates[key];
      }

      return metricEvaluatesByFilter;
    }
  },
  methods: {
    oncancelAudit() {
      this.visibleModalAudit = false;
      this.activeRealmNoRemark = '';
    },
    onOkModalAudit() {
      this.visibleModalAudit = false;
      this.activeRealmNoRemark = '';
      this.getDetails();
    },
    async handleAudit(status) {
      const _this = this;
      if (status == 'pass') {
        const res = await apiPostCountyAuditingByRealm({
          projectResultId: _this.projectResultId,
          auditingStatus: 4,
          remark: '',
          realmNo: _this.realmView.realmNo
        });
        if (res.code === '00000') {
          message.success('核准成功');
          _this.getDetails();
        } else {
          // message.error('核准失败');
        }
      }
      if (status == 'reject') {
        _this.activeId = _this.projectResultId;
        _this.activeRealmNo = _this.realmView.realmNo;

        let realmResult = this.realmResults[Number(_this.realmView.realmNo)];

        let { countyEvaluateRemark, countyEvaluateHandleStatus } = realmResult;
        if (Number(countyEvaluateHandleStatus) == 2 || Number(countyEvaluateHandleStatus) == 3) {
          //被拒绝
          let remark = countyEvaluateRemark;
          _this.activeRealmNoRemark = remark;
        }

        _this.visibleModalAudit = true;
      }
    },
    //执行检查是否完全 + 分数
    calculationData() {
      const _this = this;
      _this.chenckQuota();
      _this.calculationAllScore();
    },
    //计算每个标相加的总分数
    calculationAllScore() {
      try {
        const _this = this;
        let metricsList = [];
        _this.realmsList.forEach((item) => {
          metricsList = metricsList.concat(item.metrics);
        });
        let score = 0;
        metricsList.forEach((item) => {
          score += Number(item.selfEvaluateScore || 0);
        });
        _this.selfEvaluateScore = Number(Number(score).toFixed(2));
      } catch (error) {
        console.log(error);
      }
    },
    //点击计算是否完成,不管是不是只有现场查看，统一判断 分数+里面的评价方式的必填校验
    chenckQuota() {
      try {
        const _this = this;
        _this.realmsList.forEach((currentRealm) => {
          currentRealm.metrics.forEach((currentZb) => {
            let complete = true;
            // if (currentZb.selfMaxScore != undefined && currentZb.selfMaxScore == 0) {
            //   currentZb.complete = complete;
            // } else {
            if (!currentZb.selfEvaluateScore && currentZb.selfEvaluateScore !== 0) {
              //没填自评分
              complete = false; //没完整
            }
            //评价对象
            let metricEvaluatesList = [];
            Object.keys(currentZb.metricEvaluates).forEach((key) => {
              metricEvaluatesList = metricEvaluatesList.concat(currentZb.metricEvaluates[key]);
            });

            metricEvaluatesList.forEach((item) => {
              // && item.type != 'scene'
              if (item.required == 'yes') {
                //说明是必填项

                if (!item.selfEvaluateEvidences || item.selfEvaluateEvidences.length <= 0) {
                  complete = false; //没完整
                } else {
                  //加了列表，没有填完

                  item.selfEvaluateEvidences.forEach((self) => {
                    if (!self.evidence && (!self.fileList || (self.fileList && self.fileList.length <= 0))) {
                      complete = false; //没完整
                    }
                  });
                }
              }
            });
            currentZb.complete = complete;
            // }
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    //设置获取来的数据到页面
    setProjectData() {
      try {
        const _this = this;
        console.log('执行了设置数据');
        let { metricResults } = _this.projectResult;

        _this.realmsList.forEach((item, index) => {
          //域
          item.metrics.forEach((metrics, metricsIndex) => {
            //指标
            let metricNo = metrics.metricNo; // 指标编号

            Object.keys(metricResults).forEach((key) => {
              //结果指标对象遍历
              if (key == metricNo) {
                // 记录的指标和数据对应
                metrics.selfEvaluateScore = metricResults[key].selfEvaluateScore;
                let evaluationMethodObject = metrics.metricEvaluates; //原始的评价方式
                Object.keys(evaluationMethodObject).forEach((metricEvaluatesKey) => {
                  //
                  evaluationMethodObject[metricEvaluatesKey].forEach((evaluationMethodObjectItem) => {
                    //每个评价方式
                    let metricEvaluateResultsSub = metricResults[key].metricEvaluateResults; // 结果评价方式对象

                    Object.keys(metricEvaluateResultsSub).forEach((metricEvaluateResultsSubKey) => {
                      if (metricEvaluateResultsSubKey == evaluationMethodObjectItem.metricEvaluateNo) {
                        let selfEvaluateEvidences =
                          metricEvaluateResultsSub[metricEvaluateResultsSubKey].selfEvaluateEvidences;

                        selfEvaluateEvidences = selfEvaluateEvidences.map((selfEvaluateEvidencesItem) => {
                          //评价方式佐证材料或者亮点 赋值
                          let fileList = selfEvaluateEvidencesItem.attachmentFiles;

                          fileList = fileList.map((file) => {
                            return {
                              uid: file.id,
                              name: file.fileName,
                              status: 'done',
                              response: 'Server Error 500',
                              url: file.url
                            };
                          });

                          return {
                            evidence: selfEvaluateEvidencesItem.evidence,
                            attachment: selfEvaluateEvidencesItem.attachment.split(','),
                            fileList: fileList
                          };
                        });

                        evaluationMethodObjectItem.selfEvaluateEvidences = selfEvaluateEvidences;
                      }
                    });
                  });
                });
              }
            });
          });
        });
        //设置完数据计算一次
        _this.calculationData();
      } catch (error) {
        console.log(error);
      }
    },
    //预览取消
    onCancelModalFile() {
      try {
        const _this = this;
        _this.visibleModalFile = false;
        _this.activeFile = {};
      } catch (error) {
        console.error(error);
      }
    },
    isImage(file) {
      const _this = this;
      let name = file.name;
      let splitList = name.split('.');
      let type = splitList[splitList.length - 1].toLowerCase();
      let imgType = ['gif', 'jpeg', 'jpg', 'png'];
      if (imgType.includes(type)) {
        // 图片，直接跳转打开
        return true;
      } else {
        return false;
      }
    },
    //预览
    async goPreview(file) {
      const _this = this;
      console.log(file);
      _this.tipText = '加载中...';
      _this.spinning = true;
      let name = file.name;
      let splitList = name.split('.');
      let type = splitList[splitList.length - 1].toLowerCase();

      let imgType = ['gif', 'jpeg', 'jpg', 'png'];
      let fileTypeList = ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx'];
      let videoType = ['mp3', 'mp4'];
      if (fileTypeList.includes(type)) {
        console.log('文件');
        if (_this.previewFlag) {
          _this.spinning = false;
          return false;
        }
        _this.previewFlag = true;
        const { uid } = file;
        const res = await previewPdfApi({ uuid: uid });
        if (res && res.code === '00000') {
          let fileNameList = res.data.fileName.split('.');
          let fileType = fileNameList[fileNameList.length - 1].toLowerCase();

          _this.activeFile = {
            pdfSrc: res.data.url,
            fileType: fileType
          };
          _this.visibleModalFile = true;
        }

        setTimeout(() => {
          _this.previewFlag = false;
        }, 1000);
      } else if (type == 'pdf') {
        _this.activeFile = {
          pdfSrc: file.url
        };
        _this.visibleModalFile = true;
      } else if (imgType.includes(type)) {
        // 图片，直接跳转打开
        window.open(file.url);
      } else if (videoType.includes(type)) {
        _this.activeFileUrl = file.url;
        _this.activeFileType = type;
        _this.visibleModalMp = true;
      } else {
        message.error('预览暂不支持当前格式的文件');
      }
      _this.spinning = false;
    },
    //领域右上角驳回理由
    handleRealmReject(e, item) {
      e.stopPropagation();

      let realmResult = this.realmResults[Number(item.realmNo)];

      let { countyEvaluateHandleStatus } = realmResult;
      if (Number(countyEvaluateHandleStatus) == 2 || Number(countyEvaluateHandleStatus) == 3) {
        //被拒绝
        this.operationLogs = this.realmOperationLogs[Number(item.realmNo)];
        this.visibleModalRemark = true;
      }
    },

    //展开所有折叠
    setCollapseActiveKey() {
      try {
        const _this = this;
        let collapseActiveKeyArray = [];
        _this.realmsList.forEach((item, index) => {
          collapseActiveKeyArray.push(index);
        });
        _this.collapseActiveKey = collapseActiveKeyArray;
      } catch (error) {
        console.log(error);
      }
    },
    /*
     *获取详情
     */
    async getDetails() {
      try {
        const _this = this;
        _this.spinning = true;
        const { code, data } = await apiGetProjectDetailByLook(_this.projectResultId);
        _this.spinning = false;
        if (code === '00000' && data) {
          console.log(data);
          const { project, projectResult, realmResults, realmOperationLogs } = data;
          const { realms } = project;
          let metricIdToKeyBySelf = 0; //自己定义的 选中每个标的key值
          _this.realmsList = realms.map((item) => {
            //增加默认的selfEvaluateEvidences评价列表
            item.metrics = item.metrics.map((sub) => {
              for (let key in sub.metricEvaluates) {
                for (let i = 0; i < sub.metricEvaluates[key].length; i++) {
                  sub.metricEvaluates[key][i].selfEvaluateEvidences = [];
                }
              }
              metricIdToKeyBySelf++;
              return {
                metricIdToKeyBySelf: metricIdToKeyBySelf,
                evaluateActiveKey: 0, //每个表里面的评价方式的选中key 0|| 1
                ...sub
              };
            });
            return {
              ...item
            };
          });
          _this.project = project; //干净的从接口拿的数据
          _this.projectResult = projectResult;
          _this.realmResults = realmResults;
          _this.realmOperationLogs = realmOperationLogs;
          //激活默认选中
          _this.$nextTick(() => {
            _this.setCollapseActiveKey();
            _this.activeMetricIdToKeyBySelf = _this.realmsList[0].metrics[0].metricIdToKeyBySelf;
            _this.setMinAndMaxMetricIdToKeyBySelf();
          });

          _this.selfEvaluateScore = projectResult.selfEvaluateScore;
          if (!isEmpty(projectResult.metricResults)) {
            _this.setProjectData();
          }

          if (projectResult.projectResultId) {
            _this.projectResultId = projectResult.projectResultId;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    //下载文件
    handleDownload(e) {
      this.tipText = '下载中...';
      this.spinning = true;
      downloadFile2(
        {
          url: e.url,
          fileName: e.name
        },
        () => {
          this.spinning = false;
        }
      );
    },
    //佐证材料默认增加一个
    selfEvaluateEvidencesEmptyAdd(list) {
      if (list.length <= 0 || !list) {
        list.push({
          evidence: '',
          attachment: [],
          fileList: []
        });
      }
      return list;
    },
    //设置最小和最大的 metricIdToKeyBySelf
    setMinAndMaxMetricIdToKeyBySelf() {
      try {
        const _this = this;
        _this.minMetricIdToKeyBySelf = _this.realmsList[0].metrics[0].metricIdToKeyBySelf;
        let lastRealm = _this.realmsList[_this.realmsList.length - 1];
        let lastMetric = lastRealm.metrics[lastRealm.metrics.length - 1];
        _this.maxMetricIdToKeyBySelf = lastMetric.metricIdToKeyBySelf;
      } catch (error) {
        console.log(error);
      }
    },
    //上一个指标
    preMetric() {
      if (this.activeMetricIdToKeyBySelf == this.minMetricIdToKeyBySelf) {
        return false;
      }
      this.activeMetricIdToKeyBySelf--;
      this.$refs.right.scrollTop = 0;
    },
    //下一个指标
    nextMetric() {
      if (this.activeMetricIdToKeyBySelf == this.maxMetricIdToKeyBySelf) {
        return false;
      }
      this.activeMetricIdToKeyBySelf++;
      this.$refs.right.scrollTop = 0;
    },
    //左侧metric点击改变激活的activeMetricIdToKeyBySelf
    changeMetric(e) {
      try {
        const _this = this;
        if (_this.activeMetricIdToKeyBySelf == e.metricIdToKeyBySelf) {
          return false;
        }
        _this.activeMetricIdToKeyBySelf = e.metricIdToKeyBySelf;
        _this.$refs.right.scrollTop = 0;
      } catch (error) {
        console.log(error);
      }
    },
    //字典评价方式转中文
    transformKeyToTextByDictionaries(key) {
      let text = '未知方式';
      this.evaluateType.forEach((item) => {
        if (key == item.value) {
          text = item.name;
        }
      });
      return text;
    },
    //转中文
    toChinesNum(num) {
      let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      let unit = ['', '十', '百', '千', '万'];
      num = parseInt(num);
      let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse();
        let newNum = '';
        let newArr = [];
        strArr.forEach((item, index) => {
          newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index]);
        });
        let numArr = [];
        newArr.forEach((m, n) => {
          if (m !== '零') numArr.push(n);
        });
        if (newArr.length > 1) {
          newArr.forEach((m, n) => {
            if (newArr[newArr.length - 1] === '零') {
              if (n <= numArr[numArr.length - 1]) {
                newNum += m;
              }
            } else {
              newNum += m;
            }
          });
        } else {
          newNum = newArr[0];
        }
        return newNum;
      };
      let overWan = Math.floor(num / 10000);
      let noWan = num % 10000;
      if (noWan.toString().length < 4) {
        noWan = '0' + noWan;
      }
      return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num);
    }
  }
};
</script>

<style lang="less" scoped>
@import './index.less';
</style>
<style lang="less">
.ant-collapse {
  border: none;
  background: transparent;
}
.ant-collapse-borderless > .ant-collapse-item {
  border: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.assessment-wrap .ant-upload-list {
  display: none;
}
.evaluate-item .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  background: #e5eeff;
  border-radius: 2px;
}
</style>
