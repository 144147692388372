<template>
  <div ref="newModal">
    <a-modal
      :getContainer="() => $refs.newModal"
      title="驳回理由"
      visible
      :width="800"
      @cancel="onCancel"
      @ok="onOk"
      :maskClosable="false"
      :keyboard="false"
    >
      <section class="layout-modal">
        <section>
          <ZgyxFormGirdEdit
            ref="girdFormEdit"
            :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 20 }"
            :formData="formState"
            :configs="[
              {
                span: 24,
                type: 'textarea',
                label: '驳回理由',
                key: 'remark',
                maxlength: 400,
                showCount: true,
                autoSize: { minRows: 6, maxRows: 6 },
                rules: [{ required: true }]
              }
            ]"
            @update:formData="(formData) => (formState = formData)"
          >
          </ZgyxFormGirdEdit>
        </section>
      </section>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
// apis
import { apiPostCountyAuditingByRealm } from '@src/apis/community';
// utils
// mixins
// configs
// components
import { ZgyxFormGirdEdit } from '@src/components';
export default {
  name: '',
  components: { ZgyxFormGirdEdit, PlusOutlined },
  emits: ['onOk', 'onCancel'],
  props: {
    activeId: {
      type: [String, Number],
      default: ''
    },
    realmNo: {
      type: [String, Number],
      default: ''
    },
    activeRealmNoRemark: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    const formState = {
      remark: ''
    };
    return {
      formState
    };
  },
  computed: {
    ...mapGetters([])
  },
  created() {
    this.formState.remark = this.activeRealmNoRemark;
  },
  mounted() {
    const _this = this;
  },
  methods: {
    /**
     *
     * @param
     */

    async handleReject() {
      const _this = this;
      const { remark } = _this.formState;
      const res = await apiPostCountyAuditingByRealm({
        projectResultId: _this.activeId,
        auditingStatus: 3,
        remark: remark,
        realmNo: _this.realmNo
      });
      console.log(res);
      if (res.code === '00000') {
        message.success('核准成功');
        _this.$emit('onOk');
      } else {
        // message.error('核准失败');
      }
    },

    /**
     * 点击确定
     */
    async onOk() {
      try {
        const _this = this;
        const { isOk } = await _this.$refs.girdFormEdit.validate();
        if (isOk) {
          //处理
          _this.handleReject();
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 点击取消
     */
    onCancel() {
      try {
        const _this = this;
        _this.$emit('onCancel');
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.layout-modal {
  height: 30vh;
  padding: 20px;
  overflow-y: auto;
}
</style>
